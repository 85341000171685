<template>
    <div class="settings-content">
		<template v-if="loaded">
			<div class="opacity-page">
				<div class="list-crud row-space-tbf">
					<div class="space-left"></div>
					<div class="content">
						<div class="list">
							<div class="item" v-for="pipeline in pipelines" @click="viewItemFunc(pipeline)" v-bind:class="{active: pipelineSelected.id == pipeline.id}">
								<div class="name">{{ pipeline.name }}</div>
								<div class="type_action">{{ $t(`pipelines.types.${pipeline.type}`) }}</div>
								<div class="icon is_default"><icon-crown v-if="pipeline.is_default"/></div>
								<div class="actions">
									<button @click.stop="editItem(pipeline)"><icon-edit /></button>
									<button @click.stop="showModal('delete', {type: 'pipeline', from: 'index_pipelines', model: {id: pipeline.id}})"><icon-trash /></button>
								</div>
							</div>
							<div class="item-empty" v-if="!pipelines.length">
								{{ $t('pipelines.no-result') }}
							</div>
						</div>
						<CrudPipeline ref="curdPipeline" @refresh_list="getPipelines"/>
					</div>
					<div class="space-right"></div>
				</div>
			</div>
		</template>
		<loader-settings-page v-else/>
	</div>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconCrown from '@/components/Icons/CrownDuotone'
	import IconPeople from '../../Icons/User'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconDepartment from '../../Icons/Department'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'
	import { required } from 'vuelidate/lib/validators'
	import VSwatches from 'vue-swatches'
	import 'vue-swatches/dist/vue-swatches.css'
	import CrudPipeline from './CrudPipeline'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconPeople,
			IconDepartment,
			LoaderSettingsPage,
			VSwatches,
            CrudPipeline,
			IconCrown
		},
		computed: {
			soft_organigram() {
				return this.$store.getters['applications/getApplication']('Organigrama');
			}
		},
		data() {
			return {
				loaded: false,
				parentDepartment: '',
				viewCrud: 'create',
				departments: [],
				departmentSelected: '',
				name: '',
				description: '',
				optionsUsers: [],
			  	selectedUser: '',
			  	usersSelected: [],
			  	optionsUsersMulti: [
					{
						groupName: this.$t('v-multiselect.users'),
						groupItems: []
					}
				],
				error_message: '',
				errorsBe: {},
				color: '',
				color_code: '',

                // NEW
                pipelines: [],
				pipelineSelected: '',
			}
		},
		async mounted(){
			await this.getPipelines()

			setTimeout(() => {
				var title = this.$t('settings-navbar.pipeline');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshPipelinesIndex', () => {
				this.$refs.curdPipeline.resetToList();
				this.getPipelines()
			})

			if(!this.$auth.user().rights.admins_instance_crud){
				this.$router.push({name: 'forbbiden'})
			}
		},
		validations: {
			name: {required}
		},
		methods: {
			async getPipelines(){
				await axios.get(`${this.$auth.user().instance.id}/pipelines`)
				.then(({data}) => {
					this.pipelines = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			editItem(pipeline){
				this.scrollTop();
				this.$refs.curdPipeline.viewCrud = 'edit';
				this.$refs.curdPipeline.pipelineSelected = pipeline;

				this.$refs.curdPipeline.populateCrud(pipeline);
			},
			viewItemFunc(item){
				this.scrollTop();
				this.$refs.curdPipeline.viewCrud = 'view';
				this.$refs.curdPipeline.pipelineSelected = item;
				// this.viewCrud = 'view'
				// this.departmentSelected = item
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			}
		}
	};
</script>